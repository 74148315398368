import React from "react"

// Library
import { Link } from "react-router-dom"

const NavButton = ({
  text = text,
  url = url
}) => {
  return <Link to={{ pathname: `${url}` }} className="arrow circle">{text}</Link>
}

export default NavButton