import React from "react"

const HeaderText = ({
  info = info
}) => {
  return (
    <React.Fragment>
      <p className="header_t">会津若松地方広域市町村圏整備組合様向け<br />新ごみ焼却施設整備・運営事業建設工事/施工状況ホームページ</p>
    </React.Fragment >
  )
}

export default HeaderText