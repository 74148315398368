import React from "react"

// Components
import FooterlogoList from "../../components/molecules/FooterLogoList.jsx"
import FooterLink from "../../components/molecules/FooterLink.jsx"
import Copylight from "../../components/atoms/Copylight.jsx"

// Css
import "../../assets/css/common.css"

const Footer = () => {
  return (
    <footer className="footer">
      <FooterlogoList />
      <div className="footer__inner">
        <FooterLink />
        <Copylight />
      </div>
    </footer>
  )
}

export default Footer