import React from "react"

// Library
import { Link } from "react-router-dom"

const Footerlinktab = ({
  url = url,
  text = text
}) => {
  return <Link to={{ pathname: `${url}` }} >{text}</Link>
}

export default Footerlinktab