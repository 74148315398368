import React from "react"

// Library
import { Breadcrumb } from 'antd';

// Components
import SplitTit from "../../components/atoms/SplitTit.jsx"

// Img
import ImgLayoutDraw from "../../assets/img/layoutdraw.png"
import ImgProcessFlow from "../../assets/img/processflow.png"

const TopContents = () => {

  return (
    <div className="wrapperInner__split--main">
      <Breadcrumb separator=">" className="breadcrumb">
        <Breadcrumb.Item href="/">Top</Breadcrumb.Item>
        <Breadcrumb.Item>建設工事概要</Breadcrumb.Item>
      </Breadcrumb>
      <section className="splitmain__block">
        <SplitTit text="事業概要" />
        <table border="1" className="businesstable">
          <colgroup class="maker" span="1" />
          <thead>
            <tr className="businesstable__tit">
              <th>項目</th>
              <th>内容</th>
              <th>項目</th>
              <th>内容</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="businesstable__subtit">事業内容</td>
              <td>新ごみ焼却施設整備・運営事業</td>
              <td className="businesstable__subtit">ボイラー蒸気条件</td>
              <td>6.0MPa×450℃</td>
            </tr>
            <tr>
              <td className="businesstable__subtit">建設場所</td>
              <td>福島県会津若松市神指町大字南四合字才ノ神504番地外</td>
              <td className="businesstable__subtit">タービン定格出力</td>
              <td>6,120kW</td>
            </tr>
            <tr>
              <td className="businesstable__subtit">事業方式</td>
              <td>DBO方式<br />（Design:設計、Build:建設、Operate:運営）</td>
              <td className="businesstable__subtit">発電効率</td>
              <td>25.5%</td>
            </tr>
            <tr>
              <td className="businesstable__subtit">処理方式</td>
              <td>ストーカ炉（連続運転式）</td>
              <td rowspan="4" className="businesstable__subtit">排気ガス基準値<br />（O2 12%換算）</td>
              <td rowspan="4" className="paddingN">
                <table className="businesstable__subT">
                  <tr>
                    <td>はいじん</td>
                    <td>0.01g/m<small>3</small>N</td>
                  </tr>
                  <tr>
                    <td>塩化水素（HCL）</td>
                    <td>50ppm</td>
                  </tr>
                  <tr>
                    <td>硫黄酸化物（SOx）</td>
                    <td>50ppm</td>
                  </tr>
                  <tr>
                    <td>窒素酸化物（NOx）</td>
                    <td>100ppm</td>
                  </tr>
                  <tr>
                    <td>水銀</td>
                    <td>30μg/m<small>3</small>N</td>
                  </tr>
                  <tr>
                    <td>ダイオキシン類</td>
                    <td>0.1ng-TEQ/m<small>3</small>N</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td className="businesstable__subtit">処理能力</td>
              <td>196t/日（98t/日 × ２炉）</td>
            </tr>
            <tr>
              <td className="businesstable__subtit">設計・建設<br />工事期間</td>
              <td>令和3年8月から令和8年3月まで<br />（既設し尿処理施設解体工事及び試運転期間を含む）</td>
            </tr>
            <tr>
              <td className="businesstable__subtit">運営業務期間</td>
              <td>令和8年3月から令和23年2月まで（15年間）</td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="splitmain__block">
        <SplitTit text="配置図" />
        <figure><img src={ImgLayoutDraw} alt="配置図イメージ" /></figure>
      </section>
      <section className="splitmain__block">
        <SplitTit text="処理フロー" />
        <div className="splitmain__block--half">
          <p>ごみの焼却によって発生した熱エネルギーをボイラーで回収して高温高圧の蒸気を作ります。この蒸気の力で蒸気タービンを回転させ、発電機を通して発電します。<br />
          ごみの焼却処理で発生する排ガスを焼却炉に再循環させることで、より少ない空気でごみを完全燃焼させ、排ガス量を低減します。<br />
          消石灰・活性炭を吹き込み、ろ過式集じん器で集じんすることで排ガスをきれいにします。
          </p>
          <figure><img src={ImgProcessFlow} alt="処理フローイメージ図" /></figure>
        </div>
      </section>
    </div>
  )
}

export default TopContents