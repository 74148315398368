import React from "react"

// Library
import { Link } from "react-router-dom"
import Zoom from "react-medium-image-zoom"

// Components
import SideNavNoAuth from "../../components/molecules/SideNavNoAuth.jsx"
import TopTit from "../../components/molecules/TopTit.jsx"

// Img
import MainVisual from "../../assets/img/top_img.png"
import NODATA from "../../assets/img/nodata.png"

// Css
import 'react-medium-image-zoom/dist/styles.css'

const TopContents = ({
  infos = infos,
  latest = latest
}) => {
  const imgSrc = "data:image/png;base64," + latest
  let info = []

  if (infos.length !== 0) {
    infos.map((val, i) => {
      const date = val.INFO_DATE.split("/");
      let link = null;
      if (val.INFO_LINK === "status") link = `/${val.INFO_LINK}`
      else if (val.INFO_LINK === "fullview") link = `/${val.INFO_LINK}`
      info.push(
        <React.Fragment>
          <div className="infoInner">
            <dt key={i}>{`${date[0]}年${("0" + date[1]).slice(-2)}月${("0" + date[2]).slice(-2)}日`}</dt>
            {(() => {
              if (link !== null) return <dd><Link to={link}>{val.INFO_MESSAGE}</Link><span>NEW</span></dd>
              else return <dd>{val.INFO_MESSAGE}<span>NEW</span></dd>
            })()}
          </div>
        </React.Fragment>
      )
    })
  } else info.push(<dt className="nodata">お知らせはありません。</dt>)

  let messageImg = ""
  if (latest === "nodata") messageImg = "infocurrent__info--message"
  else if (latest === null) messageImg = "infocurrent__info--message"
  else messageImg = "infocurrent__info--messageImg"

  return (
    <React.Fragment>
      <section className="topnavvisual" >
        <SideNavNoAuth name="sidenav" />
        <div className="topnavvisual__visual">
          <h2>新ごみ焼却施設整備・運営事業建設工事<br />施工状況ホームページ</h2>
          <figure><img src={MainVisual} alt="完成イメージ図" /></figure>
        </div>
      </section>
      <div className="infocurrent">
        <section className="infocurrent__info">
          <TopTit main="お知らせ" en="What`s New" />
          {/* <dl className={latest !== null ? "infocurrent__info--messageImg" : "infocurrent__info--message"}>{info}</dl> */}
          <dl className={messageImg}>{info}</dl>
        </section>
        <section className="infocurrent__current">
          <TopTit main="建設現場の現在の様子（全景写真）" en="Current status" />
          {(() => {
            if (latest === "nodata") {
              return <div className="topInnerImg"><img src={NODATA} alt="nodata" width="100%" /></div>
            } else if (latest === null) {
              return <p className="nodata">全景写真の登録がありません。</p>
            } else {
              return <Zoom><div className="topInnerImg"> <img src={imgSrc} alt="最新写真" width="100%" /></div></Zoom>
            }
          })()}
        </section>
      </div>
      <section className="greeting">
        <TopTit main="ご挨拶" en="Greeting" />
        <div className="greeting_txt">
          <p>会津若松地方広域市町村圏整備組合が発注した「新ごみ焼却施設整備・運営事業建設工事」を受注しました川崎重工・フジタ・会津土建・梓・白井特定建設工事共同企業体でございます。<br />
          今回の工事は、一級河川阿賀野川水系の阿賀川に隣接する限られた敷地内において、稼働を停止しているし尿処理施設を解体した跡地に新施設を建設する「スクラップ＆ビルド方式」により、建設を段階的に進め、すべての工事完了は、2026年3月2日の予定です。<br />
          今後は、組合を構成する自治体をはじめ、地域の理解を得ながら、工事期間中における円滑な廃棄物処理体制の確保に万全を期すとともに、安全確保や周辺環境への配慮を最優先に工事を進めていきます。</p>
        </div>
      </section>
    </React.Fragment >
  )
}

export default TopContents