import axiosBase from 'axios'

const axios = axiosBase.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
  responseType: 'json'
})

const Request = {
  GET: (url, params, callback) => {
    axios.get(url, params).then(res => resFunc(res, callback)).catch(errFunc)
  },
  POST: (url, params, callback) => {
    axios.post(url, params).then(res => resFunc(res, callback)).catch(errFunc)
  },
  PUT: (url, params, callback) => {
    axios.put(url, params).then(res => resFunc(res, callback)).catch(errFunc)
  },
  DELETE: (url, params, callback) => {
    axios.delete(url, params).then(res => resFunc(res, callback)).catch(errFunc)
  }
}

const resFunc = (res, callback) => {
  if (res.status === 200) {
    callback(res)
  } else {
    // console.log(res)
  }
}

const errFunc = err => {
  // console.log(err)
}

export default Request
