import React from "react"

// Library
import { Breadcrumb } from 'antd'
import Iframe from 'react-iframe'

// Components
import SplitTit from "../../components/atoms/SplitTit.jsx"

// Img
import ImgGuideMain from "../../assets/img/guide_main.png"

const GuideContents = () => {
  return (
    <div className="wrapperInner__split--main">
      <Breadcrumb separator=">" className="breadcrumb">
        <Breadcrumb.Item href="/">Top</Breadcrumb.Item>
        <Breadcrumb.Item>案内図</Breadcrumb.Item>
      </Breadcrumb>
      <section className="splitmain__block">
        <SplitTit text="案内図" />
        <div className="guidelayout">
          <dl>
            <dt>● 最寄り駅</dt>
            <dd>JR会津若松駅から車で約20分</dd>
            <dt>● 最寄りIC</dt>
            <dd>会津若松ICから車で約20分</dd>
          </dl>
          <figure><img src={ImgGuideMain} alt="案内図イメージ" /></figure>
        </div>
        <Iframe
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4240.779999689925!2d139.89164677730494!3d37.49455503366452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5ff55376515a0db9%3A0xb0190d787326d056!2z44CSOTY1LTA4NTgg56aP5bO255yM5Lya5rSl6Iul5p2-5biC56We5oyH55S65aSn5a2X5Y2X5Zub5ZCI5rex5bed6KW_!5e0!3m2!1sja!2sjp!4v1640154934324!5m2!1sja!2sjp"
          width="100%"
          className="guidemap"
          loading="lazy"
          allowfullscreen=""
        />
      </section>
    </div>
  )
}

export default GuideContents