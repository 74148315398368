import React from "react"

// Library
import { useLocation } from "react-router-dom"
import { Modal } from 'antd'

// Components
import FullviewUpdateContents from "../organisms/FullviewUpdateContents.jsx"
import SideNavAuth from "../../components/molecules/SideNavAuth.jsx"
import Header from "../organisms/Header.jsx"
import Footer from "../organisms/Footer.jsx"

// AmplifyAuthenticator:cognito
import Amplify, { I18n } from 'aws-amplify'
import { AmplifyAuthenticator, AmplifySignOut, AmplifySignIn } from "@aws-amplify/ui-react"
import awsconfig from "../../utils/aws-exports.js"

// Utils
import Dict from "../../utils/dict.js"

// Css
import "../../assets/css/list.css"

I18n.putVocabularies(Dict);
I18n.setLanguage('ja');
Amplify.configure(awsconfig);

const FullviewUpdate = () => {

  const location = useLocation();
  const state = location.state;
  const { confirm } = Modal;

  return (
    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in" hideSignUp={true} />
      <Header />
      <div className="wrapperInner__split">
        <SideNavAuth name="sidenav splitside" />
        {(() => {
          if (state !== undefined) {
            return <FullviewUpdateContents state={state} />
          } else {
            confirm({
              content: <p>全景写真の編集ページへは<br />登録一覧ページ上の「編集」からアクセスください。</p>,
              onOk() {
                window.history.pushState(null, null, '/list')
                window.location.reload()
              }
            })
          }
        })()}
      </div>
      <Footer />
    </AmplifyAuthenticator>
  )
}

export default FullviewUpdate