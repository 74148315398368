import React, { useState } from "react"

// Library
import { useForm } from "react-hook-form"
import { Modal, Breadcrumb } from 'antd'

// Conponents
import CrudTit from "../../components/atoms/CrudTit.jsx"
import ImgStandardSize from "../../components/atoms/ImgStandardSize.jsx"
import Loading from "../../Loading.jsx"

// Utils
import Request from "../../utils/request.js"
import { HOST } from "../../utils/config.js"
import history from "../../utils/history.js"
import enterInvalid from "../../utils/enterInvalid.js"

// Css
import "../../assets/css/list.css"

// Img
import NODATA from "../../assets/img/nodata.png"

const StatusUpdateContents = ({
  state = state
}) => {
  enterInvalid()
  const { confirm } = Modal;
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ criteriaMode: 'all' });

  let imgSrc = ""
  if (state.img64 !== null) imgSrc = "data:image/png;base64," + state.img64
  else imgSrc = null

  const [loding, setLoding] = useState(false)
  const [fileUrl, setFileUrl] = useState(imgSrc);
  const [fileInfo, setFileInfo] = useState(state.img64 !== null ? state.img : null)
  const [pdfName, setPdfName] = useState(state.pdf64 !== null ? state.pdf : null)
  const [imgUpdate, setImgUpdate] = useState(null)
  const [pdfUpFlg, setPdfUpFlag] = useState(false)
  const [valImgFormat, setValImgFormat] = useState(false)
  const [imgWidth, setImgWidth] = useState(false)
  const [imgCheck, setImgCheck] = useState(false)

  const year = state.display_date.slice(0, 4)
  const month = state.display_date.substr(4, 2)
  const day = state.display_date.substr(6, 2)

  const ImageToBase64 = (mime_type) => {
    let img = document.getElementById("img-hide");
    let canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL(mime_type);
  }

  const ImageToPdfBase64 = async () => {
    let selectedFile = document.getElementById("pdf").files;
    let fileToLoad = selectedFile[0];
    let blob = new Blob([fileToLoad], { type: 'application/pdf' });
    let base64 = ""
    const fileReader = new FileReader();
    let promise = new Promise(function (resolve) {
      fileReader.onload = function (e) {
        base64 = e.target.result;
        resolve();
      };
    })
    fileReader.readAsDataURL(blob);
    await promise;
    return base64;
  }

  const onSubmit = (updata_data) => {
    // NODATA（img）
    if (fileInfo === null) {
      setImgCheck(true)
      return
    }
    if (valImgFormat === true) return
    if (imgWidth === true) {
      setImgWidth(true)
      return
    } else {
      setImgWidth(false)
    }
    const statusUpdateAll = async () => {
      try {
        let date = []

        // 画像 + PDFのパターンあり（1:画像＋PDf変更、2:画像のみ変更、3:PDFのみ変更、4：変更なし）
        // 1:画像＋PDf変更あり
        if ((imgUpdate !== null) && (pdfUpFlg === true)) {
          const base = ImageToBase64("image/jpeg");
          let pdfBase = ""
          pdfBase = await ImageToPdfBase64();
          date = {
            regist: state.date,
            year: year, // 変更NG
            month: month, // 変更NG
            day: day, // 変更NG
            info: updata_data.info,
            photo: base,
            name: imgUpdate.name,
            create: state.create,
            update: state.update,
            pdfname: pdfName,
            pdf64: pdfBase,
            oldpdf: state.pdf,
            oldimg: state.img,
            search: state.search
          }
        } else if ((imgUpdate !== null) && (pdfUpFlg === false)) {
          //2:画像のみ変更
          const base = ImageToBase64("image/jpeg");
          date = {
            regist: state.date,
            year: year,
            month: month,
            day: day,
            info: updata_data.info,
            photo: base,
            name: imgUpdate.name,
            create: state.create,
            update: state.update,
            pdfname: state.pdf,
            pdf64: state.pdf64,
            oldpdf: state.pdf,
            oldimg: state.img,
            search: state.search
          }
        } else if ((imgUpdate === null) && (pdfUpFlg === true)) {
          //3:PDFのみ変更
          let pdfBase = ""
          pdfBase = await ImageToPdfBase64();
          date = {
            regist: state.date,
            year: year,
            month: month,
            day: day,
            info: updata_data.info,
            photo: state.img64,
            name: state.img,
            create: state.create,
            update: state.update,
            pdfname: pdfName,
            pdf64: pdfBase,
            oldpdf: state.pdf,
            oldimg: state.img,
            search: state.search
          }
        } else {
          // 変更なし（保持しているデータそのまま）
          date = {
            regist: state.date,
            year: year,
            month: month,
            day: day,
            info: updata_data.info,
            photo: state.img64,
            name: state.img,
            create: state.create,
            update: state.update,
            pdfname: state.pdf,
            pdf64: state.pdf64,
            oldpdf: state.pdf,
            oldimg: state.img,
            search: state.search
          }
        }

        if (window.confirm("本当に更新しても宜しいですか？")) {
          setLoding(true)
          Request.PUT(HOST + "/api/status_updata", date,
            (req, res) => {
              if (req.status === 200) {
                if (req.data.status === "OK") {
                  setLoding(false)
                  confirm({
                    content: <p>工事便りの更新に成功しました。</p>,
                    onOk() {
                      history.push('/list');
                      window.location.reload()
                    }
                  })
                } else {
                  setLoding(false)
                  confirm({
                    content: <p>工事便りの更新に失敗しました。</p>,
                    onOk() {
                    }
                  })
                }
              } else { console.log("通信に失敗しました") }
            }
          )
        }
      } catch (e) { console.log("(status_updata)Err:" + e) }
    }
    statusUpdateAll()
  }

  const cancel = () => {
    if (window.confirm("本当にキャンセルしても宜しいですか？")) {
      history.push('/list');
      window.location.reload()
    }
  }

  const processImage = (e) => {
    const imageFile = e.target.files[0];
    const imageUrl = URL.createObjectURL(imageFile);
    setFileUrl(imageUrl)
    setFileInfo(imageFile.name)
    // lavelでinputを囲む（画像名の更新のためカスタマイズ）と更新押下時にファイルデータが取得できないので更新された時にstateで保存
    setImgUpdate(imageFile)
    setImgCheck(false)
    const imgIndex = imageFile.name.lastIndexOf(".")
    const imgStr = imageFile.name.substring(imgIndex)
    const imgWidthCheck = async (imageFile) => {
      let img = new Image();
      img.onload = () => {
        const size = {
          width: img.naturalWidth,
          height: img.naturalHeight,
        };
        URL.revokeObjectURL(img.src);
        if (size.width > 960) {
          setImgWidth(true)
          return
        } else {
          setImgWidth(false)
        }
      };
      img.src = URL.createObjectURL(imageFile);
    }
    const imgWidthCheckAll = async () => {
      try {
        await imgWidthCheck(imageFile);
      } catch (e) { console.log("(status_update：imgWidthCheck)Err:" + e) }
    }
    imgWidthCheckAll()
    if (imgStr.match(/.jpg/i)) {
      setValImgFormat(false)
    } else {
      setValImgFormat(true)
      return
    }
  }

  const processPdf = (e) => {
    const PdfName = e.target.files[0].name;
    setPdfName(PdfName)
    setPdfUpFlag(true)
  }

  return (
    <React.Fragment>
      {(() => { if (loding === true) return <Loading /> })()}
      <div className="wrapperInner__split--main">
        <Breadcrumb separator=">" className="breadcrumb">
          <Breadcrumb.Item href="/">Top</Breadcrumb.Item>
          <Breadcrumb.Item href="/list">登録一覧</Breadcrumb.Item>
          <Breadcrumb.Item>編集</Breadcrumb.Item>
        </Breadcrumb>
        <div className="formWrap">
          <CrudTit text="工事便り：編集" />

          <form className="systemform" onSubmit={handleSubmit(onSubmit)}>
            <div className="formWrap__inner">
              <div className="crudform__inner">
                <label className="crudblock--tit">日付</label>
                <div className="edit">
                  <p className="noedit">{year}</p><span className="search_start__label">年</span>
                  <p className="noedit">{month}</p><span className="search_start__label">月</span>
                  <p className="noedit">{day}</p><span className="search_start__label">日</span>
                </div>
              </div>
              <p className="cautionR">※「年」「月」「日」は一度登録すると、以降変更できません。</p>
              <section className="crudform__innerImg crudblock">
                <label className="crudblock--tit">画像</label>
                {/* {(() => { if (fileUrl !== null) return <figure><img src={fileUrl} id="img" className="previewImg" /><img hidden src={fileUrl} id="img-hide" /></figure> })()} */}
                {fileUrl !== null ? <figure><img src={fileUrl} id="img" className="previewImg" /><img hidden src={fileUrl} id="img-hide" /></figure> : <figure><img src={NODATA} /></figure>}
                <div className="crudform__innerImg--input">
                  {(() => {
                    if (fileInfo === null) return <p>選択してください</p>
                    else return <p>{fileInfo}</p>
                  })()}
                  <label className="imgLabel">
                    <input
                      className="imgInput"
                      type="file"
                      accept=".jpg"
                      {...register("photo")}
                      onChange={processImage} />ファイルを選択</label>
                </div>
                {(() => { if (imgCheck === true) return <p><span className="errMassage">「画像」は必須項目です。</span></p> })()}
                {(() => { if (valImgFormat === true) return <p><span className="errMassage">ファイル形式が不正です。</span></p> })()}
                {(() => { if (imgWidth === true) return <p><span className="errMassage">ファイルの横幅が規定サイズを超えています。</span></p> })()}
                <ImgStandardSize size="960" />
              </section>
              <section className="crudblock">
                <label className="crudblock--tit">補足情報</label>
                <input className="crudblockSingleI" defaultValue={state.info} {...register("info")} />
              </section>
              <section className="crudblock">
                <label className="crudblock--tit">PDF</label>
                <div className="crudform__innerImg--input">
                  {(() => {
                    if (pdfName === null) return <p>選択してください</p>
                    else return <p>{pdfName}</p>
                  })()}
                  <label className="imgLabel">
                    <input
                      className="imgInput"
                      type="file"
                      accept=".pdf"
                      id="pdf"
                      {...register("pdf")}
                      onChange={processPdf} />ファイルを選択</label>
                </div>
              </section>
              <div className="buttonDouble">
                <input type="submit" value="更新" className="buttonDouble__submit" />
                <input value="キャンセル" onClick={cancel} className="buttonDouble__cancel" />
              </div>
            </div>
          </form>

        </div>
      </div>
    </React.Fragment>
  )
}

export default StatusUpdateContents