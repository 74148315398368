import React from "react"

// Components
import TopTitEn from "../atoms/TopTitEn.jsx"
import TopTitMain from "../atoms/TopTitMain.jsx"

const TopTit = ({
  main = main,
  en = en
}) => {
  return (
    <div className="toptit">
      <TopTitEn text={en} />
      <TopTitMain text={main} />
    </div>
  )
}

export default TopTit