import React from "react"

// Components
import ProcessContents from "../organisms/ProcessContents.jsx"
import SideNavNoAuth from "../../components/molecules/SideNavNoAuth.jsx"
import Header from "../organisms/Header.jsx"
import Footer from "../organisms/Footer.jsx"

// Css
import "../../assets/css/main.css"
import "../../assets/css/common.css"

const Process = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="wrapperInner__split">
        <SideNavNoAuth name="sidenav splitside" />
        <ProcessContents />
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default Process