import React, { useEffect, useState } from "react"

// Library
import { Link } from "react-router-dom"
import { Modal, Tabs, Breadcrumb } from 'antd'
import { Pagination } from 'antd'
import 'antd/dist/antd.css'

// Components
import Loading from "../../Loading.jsx"

// Utils
import Request from "../../utils/request.js"
import { HOST } from "../../utils/config.js"
import enterInvalid from "../../utils/enterInvalid.js"

// Img
import SingleImg from "../../components/atoms/SingleImg.jsx"
import NODATA from "../../assets/img/nodata.png"

// Css
import "../../assets/css/list.css"

const ListContents = () => {

  enterInvalid()

  const [status, setStatus] = useState([])
  const [fullview, setFullview] = useState([])
  const [loding, setLoding] = useState(true)
  const [deleteLoding, setDeleteLoding] = useState(false)
  const { confirm } = Modal;
  const { TabPane } = Tabs;
  const [statusPageUnit, setStatusPageUnit] = useState(10)

  const [statusGetMaxNum, setStatusGetMaxNum] = useState("")
  const [fullviewGetMaxNum, setFullviewGetMaxNum] = useState("")
  const [statusDefaultCurrent, setStatusDefaultCurrent] = useState(1)
  const [fullviewDefaultCurrent, setFullviewDefaultCurrent] = useState(1)

  let status_slice = []
  let status_fix = []

  const [fullviewPageUnit, setFullViewPageUnit] = useState(10)
  let fullview_slice = []
  let fullview_fix = []

  useEffect(() => {
    (async () => {
      await Request.GET(HOST + "/api/list", {},
        (req, res) => {
          setLoding(false)
          if (req.data.status === "OK") {
            setStatus(req.data.statusData)
            setFullview(req.data.fullviewData)
            setStatusGetMaxNum(req.data.statusMaxNum)
            setFullviewGetMaxNum(req.data.fullviewMaxNum)
          }
        })
    })()
  }, [])


  const statusDelete = (data) => {
    if (window.confirm("本当に削除しても宜しいですか？")) {
      setDeleteLoding(true)
      Request.DELETE(HOST + "/api/status_delete", { data: data },
        (req, res) => {
          if (req.data.status === "OK") {
            setDeleteLoding(false)
            confirm({
              content: <p>工事便りの削除に成功しました。</p>,
              onOk() {
                window.location.reload()
              }
            })
          } else {
            confirm({
              content: <p>工事便りの削除に失敗しました。</p>,
              onOk() {
              }
            })
          }
        })
    }
  }

  const fullviewDelete = (data) => {
    if (window.confirm("本当に削除しても宜しいですか？")) {
      setDeleteLoding(true)
      Request.DELETE(HOST + "/api/fullview_delete", { data: data },
        (req, res) => {
          if (req.data.status === "OK") {
            setDeleteLoding(false)
            confirm({
              content: <p>全景写真の削除に成功しました。</p>,
              onOk() {
                window.location.reload()
              }
            })
          } else {
            confirm({
              content: <p>全景写真の削除に失敗しました。</p>,
              onOk() {
              }
            })
          }
        })
    }
  }

  // page-nation-click（status）
  const statusPagerChange = (page, pageSize) => {
    setLoding(true)
    setStatusDefaultCurrent(page)
    Request.GET(HOST + "/api/regStatusPgnationClick", {
      params: { pageIndex: page - 1, pageSize: pageSize }
    },
      (req, res) => {
        if (req.data.status === "OK") {
          setStatusPageUnit(pageSize)
          setStatus(req.data.statusData)
          if (req.data.statusData.length !== 0) {
            for (let i = 0; i <= Math.floor(req.data.statusData.length / statusPageUnit); i++) {
              status_slice.push(status.slice(i * statusPageUnit, statusPageUnit * (i + 1)))
            }
          }
          setLoding(false)
        } else {
          console.log("検索失敗")
        }
      })

  }

  // page-nation-click（fullview）
  const fullviewPagerChange = (page, pageSize) => {
    setLoding(true)
    setFullviewDefaultCurrent(page)
    Request.GET(HOST + "/api/regFullviewPgnationClick", {
      params: { pageIndex: page - 1, pageSize: pageSize }
    },
      (req, res) => {
        if (req.data.status === "OK") {
          setFullViewPageUnit(pageSize)
          setFullview(req.data.fullviewData)
          if (req.data.fullviewData.length !== 0) {
            for (let i = 0; i <= Math.floor(req.data.fullviewData.length / fullviewPageUnit); i++) {
              fullview_slice.push(fullview.slice(i * fullviewPageUnit, fullviewPageUnit * (i + 1)))
            }
          }
          setLoding(false)
        } else {
          console.log("検索失敗")
        }
      })

  }

  if (status.length !== 0) {
    for (let i = 0; i <= Math.floor(status.length / statusPageUnit); i++) {
      status_slice.push(status.slice(i * statusPageUnit, statusPageUnit * (i + 1)))
    }
  }
  if (fullview.length !== 0) {
    for (let i = 0; i <= Math.floor(fullview.length / fullviewPageUnit); i++) {
      fullview_slice.push(fullview.slice(i * fullviewPageUnit, fullviewPageUnit * (i + 1)))
    }
  }

  return (
    <React.Fragment>
      {(() => { if ((loding === true) || (deleteLoding === true)) return <Loading /> })()}
      <div className="wrapperInner__split--main">
        <Breadcrumb separator=">" className="breadcrumb">
          <Breadcrumb.Item href="/">Top</Breadcrumb.Item>
          <Breadcrumb.Item>登録一覧</Breadcrumb.Item>
        </Breadcrumb>

        <Tabs defaultActiveKey="1">
          <TabPane tab="工事便り" key="1">
            {(() => {
              if (status_slice.length > 0) {
                for (let i = 0; i < status_slice[0].length; i++) {
                  status_fix.push(
                    <div className="tabcontents__blk">
                      <div className="tabcontents__blk--contents">
                        <p>{status_slice[0][i].display_date.slice(0, 4)}年{status_slice[0][i].display_date.substr(4, 2)}月{status_slice[0][i].display_date.substr(6, 2)}日</p>
                        {status_slice[0][i].img64 !== null ? <figure><SingleImg imgsrc={status_slice[0][i].img64} /></figure> : <figure><img src={NODATA} alt="nodata" /></figure>}
                        <span>{status_slice[0][i].info}</span>
                      </div>
                      <div className="tabcontents__blk--btn">
                        <Link to={{ pathname: `/list/status_update`, state: status_slice[0][i] }}>編集</Link>
                        <button onClick={() => statusDelete(status_slice[0][i])}>削除</button>
                      </div>
                    </div>
                  )
                }
                return status_fix
              } else {
                return <p className="nodata">工事便り：登録データがありません。</p>
              }
            })()}
            {(() => {
              if ((status_slice.length > 0) && (statusGetMaxNum)) {
                return (
                  <Pagination
                    current={statusDefaultCurrent}
                    total={statusGetMaxNum}
                    onChange={statusPagerChange}
                  />
                )
              }
            })()}
          </TabPane>
          <TabPane tab="全景写真" key="2">
            {(() => {
              if (fullview_slice.length > 0) {
                for (let i = 0; i < fullview_slice[0].length; i++) {
                  fullview_fix.push(
                    <div className="tabcontents__blk">
                      <div className="tabcontents__blk--contents">
                        <p>{fullview_slice[0][i].date.slice(0, 4)}年{fullview_slice[0][i].date.substr(4, 2)}月{fullview_slice[0][i].date.substr(6, 2)}日</p>
                        {fullview_slice[0][i].img64 !== null ? <figure><SingleImg imgsrc={fullview_slice[0][i].img64} /></figure> : <figure><img src={NODATA} alt="nodata" /></figure>}
                      </div>
                      <div className="tabcontents__blk--btn">
                        <Link to={{ pathname: `/list/fullview_update`, state: fullview_slice[0][i] }}>編集</Link>
                        <button onClick={() => fullviewDelete(fullview_slice[0][i])}>削除</button>
                      </div>
                    </div>
                  )
                }
                return fullview_fix
              } else {
                return <p className="nodata">全景写真：登録データがありません。</p>
              }
            })()}
            {(() => {
              if ((fullview_slice.length > 0) && (fullviewGetMaxNum)) {
                return (
                  <Pagination
                    current={fullviewDefaultCurrent}
                    total={fullviewGetMaxNum}
                    onChange={fullviewPagerChange}
                  />
                )
              }
            })()}
          </TabPane>
        </Tabs>
      </div>
    </React.Fragment>
  )
}

export default ListContents