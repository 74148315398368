import React from "react"

// Components
import FullviewContents from "../../containers/organisms/FullviewContents.jsx"
import SideNavNoAuth from "../../components/molecules/SideNavNoAuth.jsx"
import Header from "../organisms/Header.jsx"
import Footer from "../organisms/Footer.jsx"

// Css
import "../../assets/css/main.css"
import "../../assets/css/common.css"
import 'antd/dist/antd.css'
import 'react-medium-image-zoom/dist/styles.css'

const FullView = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="wrapperInner__split">
        <SideNavNoAuth name="sidenav splitside" />
        <FullviewContents />
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default FullView