import React from "react"

const YearMonthDayTit = ({
  date = date
}) => {

  const yearMonthDay = date.split("/")[0] + date.split("/")[1] + date.split("/")[2]
  const year = yearMonthDay.slice(0, 4)
  const month = yearMonthDay.substr(4, 2)
  const day = yearMonthDay.substr(6, 2)
  return <p className="searchContents__tit">{year}年{month}月{day}日</p>
}

export default YearMonthDayTit