import React, { useEffect, useState } from "react"

// Components
import TopContents from "../organisms/TopContents.jsx"
import Header from "../organisms/Header.jsx"
import Footer from "../organisms/Footer.jsx"
import Loading from "../../Loading.jsx"

// Utils
import Request from "../../utils/request.js"
import { HOST } from "../../utils/config.js"

// Css
import "../../assets/css/top.css"
import 'react-medium-image-zoom/dist/styles.css'

const Top = () => {

  const [infos, setInfo] = useState([])
  const [latest, setlatest] = useState(null)
  const [loding, setLoding] = useState(true)

  useEffect(() => {
    (async () => {
      await Request.GET(HOST + "/api/info_read", {},
        (req, res) => {
          setLoding(false)
          if (req.data.status === "OK") {
            setInfo(req.data.data)
            setlatest(req.data.latest.base64)
          }
        })
    })()
  }, [])


  return (
    <React.Fragment>
      {(() => { if (loding === true) return <Loading /> })()}
      <Header />
      <TopContents infos={infos} latest={latest} />
      <Footer />
    </React.Fragment>
  )
}

export default Top