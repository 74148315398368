import React from "react"

// Library
import { Breadcrumb } from 'antd'

// Components
import SplitTit from "../../components/atoms/SplitTit.jsx"

// Img
import ImgProcessMain from "../../assets/img/process_main.png"

const ProcessContents = () => {
  return (
    <div className="wrapperInner__split--main">
      <Breadcrumb separator=">" className="breadcrumb">
        <Breadcrumb.Item href="/">Top</Breadcrumb.Item>
        <Breadcrumb.Item>工事工程</Breadcrumb.Item>
      </Breadcrumb>
      <section className="splitmain__block processimg">
        <SplitTit text="工事工程" />
        <figure><img src={ImgProcessMain} alt="工事工程イメージ" /></figure>
      </section>
    </div>
  )
}

export default ProcessContents