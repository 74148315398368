import React from "react"

// Components
import HeaderText from "../../components/atoms/HeaderText.jsx"
import Logo from "../../components/atoms/MiraizuLogo.jsx"

const Header = () => {
  return (
    <React.Fragment>
      <header>
        <HeaderText /><Logo />
      </header>
    </React.Fragment>
  )
}

export default Header