import React from "react"

// Components
import NavButton from "../atoms/NavButton.jsx"
import UnionLink from "../atoms/UnionLink.jsx";

const SideNavAuth = ({
  name = name
}) => {
  return (
    <div className="side">
      <ul className={name}>
        <li><NavButton url="/overview" text="建設工事概要" /></li>
        <li><NavButton url="/process" text="工事工程" /></li>
        <li><NavButton url="/status" text="工事便り" /></li>
        <li><NavButton url="/fullview" text="全景写真" /></li>
        <li><NavButton url="/guide" text="案内図" /></li>
        <li className="AuthMenu"><NavButton url="/status_upload" text="工事便り・登録" /></li>
        <li className="AuthMenu"><NavButton url="/fullview_upload" text="全景写真・登録" /></li>
        <li className="AuthMenu"><NavButton url="/list" text="登録一覧" /></li>
        <li className="AuthMenu"><NavButton url="/info_upload" text="お知らせ・登録" /></li>
        <li className="AuthMenu"><NavButton url="/info" text="お知らせ一覧" /></li>
      </ul>
      <div className="unionLink"><UnionLink /></div>
    </div>
  )
}

export default SideNavAuth