import React from "react"

// Components
import Footerlinktab from "../../components/atoms/Footerlinktab.jsx"

const FooterLink = () => {
  return (
    <ul className="footer__linktab">
      <li><Footerlinktab url="/overview" text="建設工事概要" /></li>
      <li><Footerlinktab url="/process" text="工事工程" /></li>
      <li><Footerlinktab url="/status" text="工事便り" /></li>
      <li><Footerlinktab url="/fullview" text="全景写真" /></li>
      <li><Footerlinktab url="/guide" text="案内図" /></li>
    </ul>
  )
}

export default FooterLink