import React from "react"

// Conponents
import InfoUploadContents from "../organisms/InfoUploadContents.jsx"
import SideNavAuth from "../../components/molecules/SideNavAuth.jsx"
import Header from "../organisms/Header.jsx"
import Footer from "../organisms/Footer.jsx"

// AmplifyAuthenticator:cognito
import Amplify, { I18n } from 'aws-amplify'
import { AmplifyAuthenticator, AmplifySignOut, AmplifySignIn } from "@aws-amplify/ui-react"
import awsconfig from "../../utils/aws-exports.js"
import Dict from "../../utils/dict.js"

// Css
import "../../assets/css/common.css"
import "../../assets/css/main.css"

I18n.putVocabularies(Dict);
I18n.setLanguage('ja');
Amplify.configure(awsconfig);

const InfoUpload = () => {

  return (
    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in" hideSignUp={true} />
      <Header />
      <div className="wrapperInner__split">
        <SideNavAuth name="sidenav splitside" />
        <InfoUploadContents />
      </div>
      <Footer />
    </AmplifyAuthenticator>
  )
}

export default InfoUpload