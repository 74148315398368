import React from "react"

// Img
import LogoKawasaki from "../../components/atoms/LogoKawasaki.jsx"
import LogoFujita from "../../components/atoms/LogoFujita.jsx"
import LogoDoken from "../../components/atoms/LogoDoken.jsx"
import LogoAzusa from "../../components/atoms/LogoAzusa.jsx"
import LogoShirai from "../../components/atoms/LogoShirai.jsx"

const FooterLogoList = () => {
  return (
    <ul className="footer__logolist">
      <li><a href="https://www.khi.co.jp/" target="_blank"><LogoKawasaki /></a></li>
      <li><a href="https://www.fujita.co.jp/" target="_blank"><LogoFujita /></a></li>
      <li><a href="https://www.adoken.co.jp/" target="_blank"><LogoDoken /></a></li>
      <li><a href="https://www.azusasekkei.co.jp/" target="_blank"><LogoAzusa /></a></li>
      <li><a href="https://shirai-sekkei.jp/" target="_blank"><LogoShirai /></a></li>
    </ul>
  )
}

export default FooterLogoList