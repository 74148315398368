import React, { useState } from "react"

// Library
import { useForm } from "react-hook-form"
import { Modal, Breadcrumb } from 'antd'
import { ErrorMessage } from '@hookform/error-message';

// Conponents
import CrudTit from "../../components/atoms/CrudTit.jsx"
import ImgStandardSize from "../../components/atoms/ImgStandardSize.jsx"
import Loading from "../../Loading.jsx"

// Utils
import Request from "../../utils/request.js"
import { HOST } from "../../utils/config.js"
import history from "../../utils/history.js"
import enterInvalid from "../../utils/enterInvalid.js"

// Css
import "../../assets/css/common.css"

const InfoUploadContents = () => {

  const now = new Date()
  const year = now.getFullYear()
  const month = ("0" + (now.getMonth() + 1)).slice(-2)
  const day = ("0" + (now.getDate())).slice(-2)
  const defalutDate = `${year}-${month}-${day}`

  const [loding, setLoding] = useState(false)
  const { confirm } = Modal;

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ criteriaMode: 'all' });


  const onSubmit = (input_data) => {
    if (window.confirm("本当に登録しても宜しいですか？")) {
      setLoding(true);
      Request.POST(HOST + "/api/info_upload", input_data,
        (req, res) => {
          if (req.status === 200) {
            if (req.data.status === "OK") {
              setLoding(false)
              confirm({
                content: <p>お知らせの登録に成功しました</p>,
                onOk() {
                  history.push('/info_upload');
                  window.location.reload()
                }
              })
            } else {
              setLoding(false)
              confirm({
                content: <p>お知らせの登録に失敗しました</p>,
                onOk() {
                }
              })
            }
          } else { console.log("通信に失敗しました") }
        }
      )
    }

  }

  const cancel = () => {
    if (window.confirm("本当にキャンセルしても宜しいですか？")) {
      history.push('/info_upload');
      window.location.reload()
    }
  }

  return (
    <React.Fragment>
      {(() => { if (loding === true) return <Loading /> })()}
      <div className="wrapperInner__split--main">
        <Breadcrumb separator=">" className="breadcrumb">
          <Breadcrumb.Item href="/">Top</Breadcrumb.Item>
          <Breadcrumb.Item>お知らせ：登録</Breadcrumb.Item>
        </Breadcrumb>


        <div className="formWrap">
          <CrudTit text="お知らせ：登録" />
          <form className="systemform" onSubmit={handleSubmit(onSubmit)}>
            <div className="formWrap__inner">

              {/* 日付 */}
              <div className="crudform__inner">
                <label className="crudblock--tit">日付</label>
                <input
                  type="date"
                  className="picker"
                  defaultValue={defalutDate}
                  min="2000-01-01"
                  max="2050-12-31"
                  {...register("date", { required: true })}
                />
                {errors.date && <p><span className="errMassage" >「日付」は必須項目です。</span></p>}
              </div>

              {/* お知らせメッセージ */}
              <section className="crudblock">
                <label className="crudblock--tit">お知らせメッセージ</label>
                <textarea
                  className="crudblockSingleI"
                  placeholder="◯◯◯◯◯が行われました。"
                  {...register("info_message",
                    {
                      required: "「お知らせメッセージ」は必須項目です。",
                      maxLength: { value: 100, message: '「お知らせメッセージ」は100文字以下でご入力下さい。' }
                    }
                  )}
                />

                {(() => {
                  if (errors.info_message !== undefined)
                    return (
                      <div className="errmassage">
                        <ErrorMessage
                          errors={errors}
                          name="info_message"
                          render={({ messages }) =>
                            messages &&
                            Object.entries(messages).map(([type, message]) => (<p key={type}><span className="errMassage">{message}</span></p>))
                          }
                        />
                      </div>
                    )
                })()}
              </section>

              {/* リンク */}
              <section className="crudblock">
                <label className="crudblock--tit">リンク</label>
                <div className="crudblock__half">
                  <div className="crudblock__halfBlk"><input {...register("info_atb", { required: true })} type="radio" value="工事便り" />工事便り</div>
                  <div className="crudblock__halfBlk"><input {...register("info_atb", { required: true })} type="radio" value="全景写真" />全景写真</div>
                  <div className="crudblock__halfBlk"><input {...register("info_atb", { required: true })} type="radio" value="なし" />なし</div>
                </div>
                {errors.info_atb && <p><span className="errMassage" >「リンク」は必須項目です。</span></p>}
              </section>


              <div className="buttonDouble">
                <input type="submit" value="登録" className="buttonDouble__submit" />
                <input value="キャンセル" onClick={cancel} className="buttonDouble__cancel" />
              </div>

            </div>
          </form>

        </div>
      </div>
    </React.Fragment>
  )
}

export default InfoUploadContents