import React, { useEffect, useState } from "react"

// Library
import { Link } from "react-router-dom"
import { Modal, Tabs, Breadcrumb, Pagination } from 'antd'
import 'antd/dist/antd.css'

// Components
import Loading from "../../Loading.jsx"

// Utils
import Request from "../../utils/request.js"
import { HOST } from "../../utils/config.js"
import enterInvalid from "../../utils/enterInvalid.js"

// Css
import "../../assets/css/list.css"

const InfoContents = () => {
  enterInvalid()

  const [info, setInfo] = useState([]);
  const [loding, setLoding] = useState(true);
  const [deleteLoding, setDeleteLoding] = useState(false)
  const { confirm } = Modal;
  const [defaultCurrent, setDefaultCurrent] = useState(1)
  const [getMaxNum, setgetMaxNum] = useState("")

  let info_fix = [];

  let pageIndex = 0;
  let slice = []
  const [CurPage, setCurPage] = useState(0);
  const [PageUnit, setPageUnit] = useState(10);

  useEffect(() => {
    (async () => {
      await Request.GET(HOST + "/api/info", {},
        (req, res) => {
          setLoding(false)
          if (req.data.status === "OK") {
            setInfo(req.data.info_data)
            setgetMaxNum(req.data.maxNum)
          }
        })
    })()
  }, [])

  // TODO: create_date順ではなく「日付」順に sortする（サーバーサイド側で処理してfrontへ）


  const infoDelete = (data) => {
    if (window.confirm("本当に削除しても宜しいですか？")) {
      setDeleteLoding(true);
      Request.DELETE(HOST + "/api/info_delete", { data: data },
        (req, res) => {
          if (req.data.status === "OK") {
            setDeleteLoding(false);
            confirm({
              content: <p>お知らせの削除に成功しました。</p>,
              onOk() {
                window.location.reload();
              }
            })
          } else {
            confirm({
              content: <p>お知らせの削除に失敗しました。</p>,
              onOk() { }
            })
          }
        })
    }
  }


  const infoPagerChange = (page, pageSize) => {
    setLoding(true)
    pageIndex = page - 1;
    setDefaultCurrent(page)
    const params = { pageIndex: pageIndex, pageSize: pageSize }
    Request.GET(HOST + "/api/info_pgnationClick", {
      params: params
    },
      (req, res) => {
        if (req.data.status === "OK") {
          setCurPage(pageIndex);
          setPageUnit(pageSize);
          setInfo(req.data.info_data)
          if (req.data.info_data.length !== 0) {
            for (let i = 0; i <= Math.floor(req.data.info_data.length / PageUnit); i++) {
              slice.push(info.slice(i * PageUnit, PageUnit * (i + 1)))
            }
          }
          setLoding(false)
        } else {
          console.log("検索失敗")
        }
      })

  }

  if (info.length !== 0) {
    for (let i = 0; i <= Math.floor(info.length / PageUnit); i++) {
      slice.push(info.slice(i * PageUnit, PageUnit * (i + 1)))
    }
  }

  return (
    <React.Fragment>
      {(() => { if ((loding === true) || (deleteLoding === true)) return <Loading /> })()}
      <div className="wrapperInner__split--main">
        <Breadcrumb separator=">" className="breadcrumb">
          <Breadcrumb.Item href="/">Top</Breadcrumb.Item>
          <Breadcrumb.Item>お知らせ一覧</Breadcrumb.Item>
        </Breadcrumb>
        <div className="infoWrapper">
          {(() => {
            if (slice.length === 0) return <p className="nodata">該当データはありません。</p>
            else {
              for (let i = 0; i < slice[0].length; i++) {
                info_fix.push(
                  <div className="infoBlk">
                    <div className="infoBlk_cts">
                      <span className="infoBlk_cts__date">{slice[0][i].INFO_DATE}</span>
                      <p className="infoBlk_cts__message">{slice[0][i].INFO_MESSAGE}</p>
                    </div>
                    <div className="infoBlk_btn">
                      <Link to={{ pathname: `/info/update`, state: slice[0][i] }}>編集</Link>
                      <button onClick={() => infoDelete(slice[0][i])}>削除</button>
                    </div>
                  </div>
                )
              }
              return info_fix
            }
          })()}
          {(() => {
            if ((info.length > 0) && (getMaxNum)) {
              return (
                <Pagination
                  current={defaultCurrent}
                  total={getMaxNum}
                  onChange={infoPagerChange}
                // pageSizeOptions={[10]}
                />
              )
            }
          })()}
        </div>

      </div>
    </React.Fragment>
  )
}

export default InfoContents