import React, { useState, useEffect } from "react"

// Library
import { useForm } from "react-hook-form"
import { Pagination, Breadcrumb } from 'antd'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import Zoom from "react-medium-image-zoom"

// Components
import YearMonthDayTit from "../../components/atoms/YearMonthDayTit.jsx"
import SingleImg from "../../components/atoms/SingleImg.jsx"
import InfoText from "../../components/atoms/InfoText.jsx"
import Loading from "../../Loading.jsx"

// Utils
import Request from "../../utils/request.js"
import { HOST } from "../../utils/config.js"

// Img
import PDFICON from "../../assets/img/icon_pdf.png"
import NODATA from "../../assets/img/nodata.png"

const StatusContents = () => {

  const now = new Date()
  const year = now.getFullYear()
  const month = ("0" + (now.getMonth() + 1)).slice(-2)
  const day = ("0" + (now.getDate())).slice(-2)
  const defalutDate = `${year}-${month}-${day}`

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ criteriaMode: 'all' });

  const [loding, setLoding] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageUnit, setPageUnit] = useState(10)
  const [getStatus, setgetStatus] = useState("")
  const [getMaxNum, setgetMaxNum] = useState("")
  const [reverse, setReverse] = useState(false)
  const [searchCheck, setSearchCheck] = useState(null)
  const [defaultCurrent, setDefaultCurrent] = useState(1)
  let slice = []
  let fix = []
  let pageIndex = 0

  useEffect(() => {
    (async () => {
      await Request.GET(HOST + "/api/status_default", {},
        (req, res) => {
          setLoding(false)
          if (req.data.status === "OK") {
            setgetStatus(req.data.statusAll)
            setgetMaxNum(req.data.maxNum)
          }
        })
    })()
  }, [])


  const onSubmit = (input_data) => {
    if (currentPage !== 0) setCurrentPage(0)
    const startData = input_data.start
    const startSplit = startData.split("-")
    const startYear = startSplit[0]
    const startMonth = startSplit[1]
    const startDay = startSplit[2]
    const endData = input_data.end
    const endSplit = endData.split("-")
    const endYear = endSplit[0]
    const endMonth = endSplit[1]
    const endDay = endSplit[2]
    const startCompare = new Date(startYear, startMonth, startDay)
    const endCompare = new Date(endYear, endMonth, endDay)

    if (startCompare > endCompare) {
      setReverse(true)
      return
    } else {
      setReverse(false)
    }
    const latestData = {
      start_year: startYear, start_month: startMonth,
      start_day: startDay, end_year: endYear,
      end_month: endMonth, end_day: endDay
    }
    setLoding(true)
    setSearchCheck(latestData)
    Request.GET(HOST + "/api/status_search", {
      params: { search_data: latestData }
    },
      (req, res) => {
        if (req.data.status === "OK") {
          setgetStatus(req.data.fixdata)
          const dcUp = 1
          setDefaultCurrent(dcUp)
          setgetMaxNum(req.data.maxNum)
          setLoding(false)
        } else {
          console.log("検索失敗")
        }
      })
  }

  // 第一引数「現在のページ」、第二引数「表示件数」
  const pagerChange = (page, pageSize) => {
    setLoding(true)
    pageIndex = page - 1
    setDefaultCurrent(page)

    // 検索条件ありの場合は検索値も付ける
    let params = {}
    if (searchCheck !== null) {
      params = { pageIndex: pageIndex, pageSize: pageSize, searchCheck: searchCheck }
    } else {
      params = { pageIndex: pageIndex, pageSize: pageSize }
    }
    Request.GET(HOST + "/api/status_pgnationClick", {
      params: params
    },
      (req, res) => {
        if (req.data.status === "OK") {
          setCurrentPage(pageIndex)
          setPageUnit(pageSize)
          setgetStatus(req.data.fixdata)
          if (req.data.fixdata.length !== 0) {
            for (let i = 0; i <= Math.floor(req.data.fixdata.length / pageUnit); i++) {
              slice.push(getStatus.slice(i * pageUnit, pageUnit * (i + 1)))
            }
          }
          setLoding(false)
        } else {
          console.log("検索失敗")
        }
      })
  }

  if (getStatus.length !== 0) {
    for (let i = 0; i <= Math.floor(getStatus.length / pageUnit); i++) {
      slice.push(getStatus.slice(i * pageUnit, pageUnit * (i + 1)))
    }
  }

  const windowPdf = (url) => { window.open(url) }

  return (
    <React.Fragment>
      {(() => { if (loding === true) return <Loading /> })()}
      <div className="wrapperInner__split--main">
        <Breadcrumb separator=">" className="breadcrumb">
          <Breadcrumb.Item href="/">Top</Breadcrumb.Item>
          <Breadcrumb.Item>工事便り</Breadcrumb.Item>
        </Breadcrumb>

        <form className="seachform" onSubmit={handleSubmit(onSubmit)}>
          <p className="seachform__tit">絞り込み検索条件</p>
          <div className="seachform__inner">
            <input
              type="date"
              className="picker"
              defaultValue={defalutDate}
              min="2000-01-01"
              max="2050-12-31"
              {...register("start", { required: true })}
            />
            <span className="seachform__inner--waveline">〜</span>
            <input
              type="date"
              className="picker"
              defaultValue={defalutDate}
              min="2000-01-01"
              max="2050-12-31"
              {...register("end", { required: true })}
            />
          </div>
          <button type="submit" className="seachform__inner--button" onSubmit={handleSubmit(onSubmit)}>
            <FontAwesomeIcon icon={faSearch} className="iconSeach" />検索
          </button>
          {errors.start && <p><span className="errMassage" >「開始：期間 （年：月）」は必須項目です。</span></p>}
          {errors.end && <p><span className="errMassage" >「終了：期間（年：月）」は必須項目です。</span></p>}
          {reverse === true && <p><span className="errMassage" >「開始」は「終了」より小さい日付にしてください。</span></p>}
        </form>

        <p className="imgCaution">画像をクリックで拡大表示されます。</p>
        {(() => {
          if (slice.length > 0) {
            for (let i = 0; i < slice[0].length; i++) {
              fix.push(
                <div className="searchContents">
                  <YearMonthDayTit date={slice[0][i].search} />
                  {slice[0][i].img64 !== null ? <Zoom><figure className="searchContents__img"><SingleImg imgsrc={slice[0][i].img64} /></figure></Zoom> : <img className="nodata-img" src={NODATA} alt="nodata" />}
                  <div className="searchContents__info">
                    <span><InfoText info={slice[0][i].info} /></span>
                    {(() => {
                      if (slice[0][i].pdf64 !== null) {
                        const pdfBase64 = "data:application/pdf;base64," + slice[0][i].pdf64
                        let blob = ""
                        let bin = atob(pdfBase64.replace(/^.*,/, ''));
                        let buffer = new Uint8Array(bin.length);
                        for (let i = 0; i < bin.length; i++) { buffer[i] = bin.charCodeAt(i); }
                        blob = new Blob([buffer.buffer], { type: "application/pdf" });
                        const pdfURL = URL.createObjectURL(blob);
                        return <img src={PDFICON} className="pdf" alt="PDFアイコン" onClick={() => windowPdf(pdfURL)} />
                      }
                    })()}
                  </div>
                </div>
              )
            }
            return fix
          } else {
            return <p className="nodata">該当データはありません。</p>
          }
        })()}
        {(() => {
          if ((slice.length > 0) && (getMaxNum)) {
            return (
              <Pagination
                current={defaultCurrent}
                total={getMaxNum}
                onChange={pagerChange}
              />
            )
          }
        })()}
      </div>
    </React.Fragment>
  )
}

export default StatusContents