import React from "react"
import ReactLoading from 'react-loading'

const Loading = () => {
  return (
    <div className="loding">
      <ReactLoading type="spin" />
    </div>
  );
};

export default Loading;
