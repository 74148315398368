// import 'react-app-polyfill/ie11';
import 'babel-polyfill';
import React from 'react'
import ReactDOM from "react-dom"
import { BrowserRouter, Route } from 'react-router-dom'

// Containers
import Top from './containers/pages/Top.jsx'
import StautsUpload from './containers/pages/StatusUpload.jsx'
import FullviewUpload from './containers/pages/FullviewUpload.jsx'
import List from './containers/pages/List.jsx'
import StatusUpdate from './containers/pages/StatusUpdate.jsx'
import FullviewUpdate from './containers/pages/FullviewUpdate.jsx'
import Status from './containers/pages/Status.jsx'
import FullView from './containers/pages/FullView.jsx'
import Overview from './containers/pages/Overview.jsx'
import Process from './containers/pages/Process.jsx'
import Guide from './containers/pages/Guide.jsx'
import InfoUpload from './containers/pages/InfoUpload.jsx'
import Info from './containers/pages/Info.jsx'
import InfoUpdate from './containers/pages/InfoUpdate.jsx'

// AWS
import Amplify from 'aws-amplify'

// Css
import "./assets/css/reset.css"
import "./assets/css/common.css"

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_IDENTITYPOOLID,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_AWS_CLIENTID
  }
});

const App = () => {
  return (
    <BrowserRouter >
      <div className="wrapper">
        <div className="wrapperInner">
          <Route exact path="/" component={Top} />
          <Route exact path="/status_upload" component={StautsUpload} />
          <Route exact path="/fullview_upload" component={FullviewUpload} />
          <Route exact path="/status" component={Status} />
          <Route exact path="/fullview" component={FullView} />
          <Route exact path="/list" component={List} />
          <Route exact path="/list/status_update" component={StatusUpdate} />
          <Route exact path="/list/fullview_update" component={FullviewUpdate} />
          <Route exact path="/overview" component={Overview} />
          <Route exact path="/process" component={Process} />
          <Route exact path="/guide" component={Guide} />
          <Route exact path="/info_upload" component={InfoUpload} />
          <Route exact path="/info/update" component={InfoUpdate} />
          <Route exact path="/info" component={Info} />
        </div>
      </div>
    </BrowserRouter >
  )
}

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);