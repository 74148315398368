import React, { useState } from "react"

// Library
import { useForm } from "react-hook-form"
import { Modal, Breadcrumb } from 'antd'
import { ErrorMessage } from '@hookform/error-message';

// Conponents
import CrudTit from "../../components/atoms/CrudTit.jsx"
import Loading from "../../Loading.jsx"

// Utils
import Request from "../../utils/request.js"
import { HOST } from "../../utils/config.js"
import history from "../../utils/history.js"
import enterInvalid from "../../utils/enterInvalid.js"

// Css
import "../../assets/css/list.css"

const InfoUpdateContents = ({
  state = state
}) => {
  enterInvalid();
  let dateTmp = state.INFO_DATE.split("/");
  const date = `${dateTmp[0]}-${("0" + dateTmp[1]).slice(-2)}-${("0" + dateTmp[2]).slice(-2)}`;
  const { confirm } = Modal;
  const [loding, setLoding] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ criteriaMode: 'all' });


  const onSubmit = (input_data) => {
    if (window.confirm("本当に更新しても宜しいでしょうか？")) {
      const data = { input_data: input_data, create_date: state.CREATE_DATE };
      setLoding(true);
      Request.PUT(HOST + "/api/info_update", data,
        (req, res) => {
          if (req.data.status === "OK") {
            setLoding(false);
            confirm({
              content: <p>お知らせの更新に成功しました。</p>,
              onOk() {
                history.push('/info');
                window.location.reload();
              }
            })
          } else {
            setLoding(false);
            confirm({
              content: <p>お知らせの更新に失敗しました。</p>,
              onOk() { }
            })
          }
        })
    }
  }

  const cancel = () => {
    if (window.confirm("本当にキャンセルしても宜しいですか？")) {
      history.push('/info');
      window.location.reload()
    }
  }


  return (
    <React.Fragment>
      {(() => { if (loding === true) return <Loading /> })()}
      <div className="wrapperInner__split--main">
        <Breadcrumb separator=">" className="breadcrumb">
          <Breadcrumb.Item href="/">Top</Breadcrumb.Item>
          <Breadcrumb.Item href="/info">お知らせ一覧</Breadcrumb.Item>
          <Breadcrumb.Item>編集</Breadcrumb.Item>
        </Breadcrumb>

        <div className="formWrap">
          <CrudTit text="お知らせ：編集" />
          <form className="systemform" onSubmit={handleSubmit(onSubmit)}>
            <div className="formWrap__inner">

              <div className="crudform__inner">
                <label className="crudblock--tit">日付</label>
                <input
                  type="date"
                  className="picker"
                  defaultValue={date}
                  min="2000-01-01"
                  max="2050-12-31"
                  {...register("date", { required: true })}
                />
                {errors.date && <p><span className="errMassage" >「日付」は必須項目です。</span></p>}
              </div>

              <section className="crudblock">
                <label className="crudblock--tit">お知らせメッセージ</label>
                <textarea
                  className="crudblockSingleI"
                  placeholder="◯◯◯◯◯が行われました。"
                  defaultValue={state.INFO_MESSAGE}
                  {...register("info_message",
                    {
                      required: "「お知らせメッセージ」は必須項目です。",
                      maxLength: { value: 100, message: '「お知らせメッセージ」は100文字以下でご入力下さい。' }
                    }
                  )}
                />

                {(() => {
                  if (errors.info_message !== undefined)
                    return (
                      <div className="errmassage">
                        <ErrorMessage
                          errors={errors}
                          name="info_message"
                          render={({ messages }) =>
                            messages &&
                            Object.entries(messages).map(([type, message]) => (<p key={type}><span className="errMassage">{message}</span></p>))
                          }
                        />
                      </div>
                    )
                })()}
              </section>

              <section className="crudblock">
                <label className="crudblock--tit">リンク</label>
                <div className="crudblock__half">
                  {state.INFO_LINK === "status" ?
                    <div className="crudblock__halfBlk"><input {...register("info_atb", { required: true })} type="radio" value="工事便り" defaultChecked />工事便り</div> :
                    <div className="crudblock__halfBlk"><input {...register("info_atb", { required: true })} type="radio" value="工事便り" />工事便り</div>}

                  {state.INFO_LINK === "fullview" ?
                    <div className="crudblock__halfBlk"><input {...register("info_atb", { required: true })} type="radio" value="全景写真" defaultChecked />全景写真</div> :
                    <div className="crudblock__halfBlk"><input {...register("info_atb", { required: true })} type="radio" value="全景写真" />全景写真</div>}

                  {state.INFO_LINK === null ?
                    <div className="crudblock__halfBlk"><input {...register("info_atb", { required: true })} type="radio" value="なし" defaultChecked />なし</div> :
                    <div className="crudblock__halfBlk"><input {...register("info_atb", { required: true })} type="radio" value="なし" />なし</div>}

                </div>
                {errors.info_atb && <p><span className="errMassage" >「リンク」は必須項目です。</span></p>}
              </section>

              <div className="buttonDouble">
                <input type="submit" value="更新" className="buttonDouble__submit" />
                <input value="キャンセル" onClick={cancel} className="buttonDouble__cancel" />
              </div>

            </div>
          </form>

        </div>
      </div>
    </React.Fragment>
  )
}

export default InfoUpdateContents