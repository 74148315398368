import React from "react"

// Library
import { Link } from "react-router-dom"

// Img
import Logo from "../../assets/img/logo_miraizu.png"

const MiraizuLogo = ({
  info = info
}) => {
  return (
    <React.Fragment>
      <Link to={{ pathname: `/` }} className="header_logo">
        <h1><img src={Logo} alt="MIRAIZU"></img></h1>
      </Link>
    </React.Fragment >
  )
}

export default MiraizuLogo