export const dict = {
  ja: {
    'Sign In': 'サインイン',
    'Sign Up': 'サインアップ',
    'Sign Out': 'サインアウト',
    'Sign in to your account': 'アカウントにサインイン',
    'Username *': 'ユーザー名 *',
    'Password *': 'パスワード *',
    'Enter your username': 'ユーザー名を入力',
    'Enter your password': 'パスワードを入力',
    'No account?': 'アカウントが未登録ですか？',
    'Forgot your password?': 'パスワードをお忘れですか？',
    'Reset password': 'パスワードをリセット',
    'User does not exist': 'ユーザーが存在しません',
    'User already exists': 'ユーザーは既に存在します',
    'Incorrect username or password.': 'ユーザー名またはパスワードが違います',
    'Invalid password format': 'パスワードのフォーマットが不正です',
    'Create account': 'アカウントを作成',
    'Forgot Password': 'パスワードを忘れた',
    'Change Password': 'パスワードを変更',
    'New Password': '新しいパスワード',
    'Email': 'Email',
    'Phone Number': '電話番号',
    'Confirm a Code': 'コードを確認',
    'Confirm Sign In': 'サインインを確認',
    'Confirm Sign up': 'サインアップを確認',
    'Back to Sign In': 'サインインに戻る',
    'Send Code': 'コードを送信',
    'Confirm': '確認',
    'Resend Code': 'コードを再送',
    'Submit': '送信',
    'Skip': 'スキップ',
    'Verify': '検証',
    'Verify Contact': '連絡先を検証',
    'Code': 'Code',
    'Confirmation Code': '確認コード',
    'Lost your code?': 'コードがありませんか？',
    'Account recovery requires verified contact information': 'Account recovery requires verified contact information',
    'Invalid phone number format': '不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900',
    'Create Account': 'アカウントを作成',
    'Have an account?': 'アカウントをお持ちですか？',
    'Sign in': 'サインイン',
    'Create a new account': '新しいアカウントを作成',
    'Reset your password': 'パスワードをリセット',
    'An account with the given email already exists.': 'そのメールアドレスは既に存在します',
    'Username cannot be empty': 'ユーザー名は必須です',
    'Password attempts exceeded': 'パスワード試行回数が超過しました',
  },
};

export default dict