import React, { useState } from "react"

// Library
import { useForm } from "react-hook-form"
import { Modal, Breadcrumb } from 'antd'

// Conponents
import CrudTit from "../../components/atoms/CrudTit.jsx"
import cognitoSignOut from "../../utils/cognitoSignOut.js"
import ImgStandardSize from "../../components/atoms/ImgStandardSize.jsx"
import Loading from "../../Loading.jsx"

// Utils
import Request from "../../utils/request.js"
import { HOST } from "../../utils/config.js"
import history from "../../utils/history.js"
import enterInvalid from "../../utils/enterInvalid.js"

// Css
import "../../assets/css/common.css"

const FullviewUploadContents = () => {

  enterInvalid()

  const now = new Date()
  const year = now.getFullYear()
  const month = ("0" + (now.getMonth() + 1)).slice(-2)
  const day = ("0" + (now.getDate())).slice(-2)
  const defalutDate = `${year}-${month}-${day}`

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ criteriaMode: 'all' });

  const [loding, setLoding] = useState(false)
  const [fileUrl, setFileUrl] = useState(null);
  const { confirm } = Modal;
  const [filename, setFilename] = useState(null)
  const [filedata, setFiledata] = useState(null)
  const [filedataCheck, setFiledataCheck] = useState(false)
  const [valImgFormat, setValImgFormat] = useState(false)
  const [imgWidth, setImgWidth] = useState(false)

  const ImageToBase64 = (mime_type) => {
    let img = document.getElementById("img-hide");
    let canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL(mime_type);
  }

  const onSubmit = (input_data) => {
    if ((filedata === null) || (filedata === false)) {
      setFiledataCheck(true)
      return
    } else {
      setFiledataCheck(false)
    }

    if (valImgFormat === true) return

    if (imgWidth === true) {
      setImgWidth(true)
      return
    } else {
      setImgWidth(false)
    }
    const base = ImageToBase64("image/jpeg");
    const startData = input_data.date
    const startSplit = startData.split("-")
    const startYear = startSplit[0]
    const startMonth = startSplit[1]
    const startDay = startSplit[2]
    let date = {
      year: startYear,
      month: startMonth,
      day: startDay,
      photo: base,
      name: filedata.name
    }

    if (window.confirm("本当に登録しても宜しいですか？")) {
      setLoding(true)
      Request.POST(HOST + "/api/fullview_upload", date,
        (req, res) => {
          if (req.status === 200) {
            if (req.data.status === "OK") {
              setLoding(false)
              confirm({
                content: <p>全景写真の登録に成功しました。</p>,
                onOk() {
                  history.push('/fullview_upload');
                  window.location.reload()
                }
              })
            } else {
              setLoding(false)
              confirm({
                content: <p>全景写真の登録に失敗しました。</p>,
                onOk() {
                }
              })
            }
          } else {
            console.log("通信に失敗しました")
          }
        }
      )
    }
  }

  // img-preview
  const processImage = (e) => {
    const imageFile = e.target.files[0];
    const imageUrl = URL.createObjectURL(imageFile);
    setFileUrl(imageUrl)
    setFiledata(imageFile)
    setFilename(imageFile.name)

    imageFile ? setFiledataCheck(false) : setFiledataCheck(true)

    const imgIndex = imageFile.name.lastIndexOf(".")
    const imgStr = imageFile.name.substring(imgIndex)

    const imgWidthCheck = async (imageFile) => {
      let img = new Image();
      img.onload = () => {
        const size = {
          width: img.naturalWidth,
          height: img.naturalHeight,
        };
        URL.revokeObjectURL(img.src);
        if (size.width > 1920) {
          setImgWidth(true)
          return
        } else {
          setImgWidth(false)
        }
      };
      img.src = URL.createObjectURL(imageFile);
    }

    const imgWidthCheckAll = async () => {
      try {
        await imgWidthCheck(imageFile);
      } catch (e) { console.log("(status_upload：imgWidthCheck)Err:" + e) }
    }
    imgWidthCheckAll()

    if (imgStr.match(/.jpg/i)) {
      setValImgFormat(false)
    } else {
      setValImgFormat(true)
      return
    }
  }

  const cancel = () => {
    if (window.confirm("本当にキャンセルしても宜しいですか？")) {
      history.push('/fullview_upload');
      window.location.reload()
    }
  }

  return (
    <React.Fragment>
      {(() => { if (loding === true) return <Loading /> })()}
      <div className="wrapperInner__split--main">
        <Breadcrumb separator=">" className="breadcrumb">
          <Breadcrumb.Item href="/">Top</Breadcrumb.Item>
          <Breadcrumb.Item>全景写真：登録</Breadcrumb.Item>
        </Breadcrumb>
        <div className="formWrap">
          <CrudTit text="全景写真：登録" />

          <form className="systemform" onSubmit={handleSubmit(onSubmit)}>
            <div className="formWrap__inner">
              <div className="crudform__inner">
                <label className="crudblock--tit">日付</label>
                <input
                  type="date"
                  className="picker"
                  defaultValue={defalutDate}
                  min="2000-01-01"
                  max="2050-12-31"
                  {...register("date", { required: true })}
                />
              </div>
              {errors.date && <p><span className="errMassage" >「日付」は必須項目です。</span></p>}
              <p className="cautionR">※「年」「月」「日」は一度登録すると、以降変更できません。</p>
              <section className="crudform__innerImg crudblock">
                <label className="crudblock--tit">画像</label>
                {(() => { if (fileUrl !== null) { return <figure><img src={fileUrl} id="img" className="previewImg" /><img hidden src={fileUrl} id="img-hide" /></figure> } })()}
                <div className="crudform__innerImg--input">
                  {(() => {
                    if (filename === null) return <p>選択してください</p>
                    else return <p>{filename}</p>
                  })()}
                  <label className="imgLabel">
                    <input
                      className="imgInput"
                      type="file"
                      accept=".jpg"
                      {...register("photo")}
                      onChange={processImage} />ファイルを選択</label>
                </div>
                {filedataCheck === true && <p><span className="errMassage">「画像」は必須項目です。</span></p>}
                {valImgFormat === true && <p><span className="errMassage">ファイル形式が不正です。</span></p>}
                {imgWidth === true && <p><span className="errMassage">ファイルの横幅が規定サイズを超えています。</span></p>}
                <ImgStandardSize size="1920" />
              </section>
              <div className="buttonDouble">
                <input type="submit" value="登録" className="buttonDouble__submit" />
                <input value="キャンセル" onClick={cancel} className="buttonDouble__cancel" />
              </div>
            </div>
          </form>

        </div>
        {/* <button onClick={cognitoSignOut}>サインアウト</button> */}
      </div>
    </React.Fragment>
  )
}

export default FullviewUploadContents