/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// prod
const awsmobile = { aws_project_region: "ap-northeast-1" };

// dev + test
// const awsmobile = { aws_project_region: "us-east-2" };

export default awsmobile;
